<script>
import Layout from "../../layouts/main";
// import ReportStatus from "./wallet-chart.vue";
import { pieChart } from "./data-apex";
import EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import axios from "axios";

import base_domain from "../../myvars";
axios.defaults.baseURL = base_domain;

export default {
  components: {
    Layout,
    EasyDataTable,
    // ReportStatus
  },
  data() {
    return {
      Total_Staff_Members: 0,
      Total_Applicants: 0,
      Total_Approved_Applications: 0,
      Total_Rejected_Applications: 0,
      Total_Incomplete_Applications: 0,
      Submitted_and_Under_Review: 0,

      // toggleBtnUrl: false,

      pieChart: pieChart,
      show_pie: false,
      searchValueTable: "",
      selectFilterSort: null,
      headers: [
        // { text: "Applicant Number", value: "applicantNum" },
        { text: "#ApplicantId", value: "applicantId" },
        { text: "Applicant Name", value: "applicantName" },
        { text: "Assign", value: "assignPrsn" },
        { text: "Date", value: "logdate" },
        { text: "Status", value: "statusLog" },
        { text: "Action", value: "actionBtn" },
        { text: "Is Verified", value: "toggleBtn" },
      ],
      items: [],
      arrItem: [],
    };
  },
  mounted() {
    this.fetchData();
    this.showdata();
    // print('this is data',this.showdata())
  },
  created() {
    this.arrItem = this.items;
  },

  methods: {
    // toggleSwitch() {
    //   if (this.toggleState) {
    //     this.$router.push(this.toggleBtnUrl);
    //   }
    // },

    filterStatus() {
      if (this.selectFilterSort == null) {
        this.arrItem = this.items;
      } else {
        this.arrItem = this.items.filter((item) => {
          return item.statusLog == this.selectFilterSort;
        });
      }
    },
    fetchData() {
      axios
        .get("/applications/all_member_count/")
        .then((response) => {
          const data = response.data;
          console.log("cheack the data came or not ", data);
          this.Total_Staff_Members = data.Total_Staff_Members;
          this.Total_Applicants = data.Total_Applicants;
          this.Total_Approved_Applications = data.Total_Approved_Applications;
          this.Total_Rejected_Applications = data.Total_Rejected_Applications;
          this.Total_Incomplete_Applications =
            data.Total_Incomplete_Applications;
          this.Submitted_and_Under_Review = data.Submitted_and_Under_Review;
          // console.log('this is for ',this.Total_Staff_Members)
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showdata() {
      let arrList = [];
      const accessToken = localStorage.getItem("accessToken");
      axios
        .get("/applications/all_application_details/", {
          params: {
            access_token: accessToken,
          },
        })
        .then((response) => {
          const user_data = response.data;
          console.log("this is for yes check", user_data);
          let responseList = response.data["all_details"];
          responseList.forEach((item) => {
            console.log("this i", item.status);
            let newItem = {
              applicantId:item.id,
              applicantName: `${
                (item.section_a1 && item.section_a1.first_name) || "N/A"
              } ${(item.section_a1 && item.section_a1.sur_name) || ""}`,
              // applicantNum: item.application_no,
              assignPrsn: item.rev,
              dateLog: item.created_on,
              statusLog: item.status,
              actionBtnUrl: `/single_applicant/${item.id}`,
              toggleBtnUrl: `${item.id}`,
            };
            arrList.push(newItem);
          });
          this.arrItem = arrList;
          // this.pieChart.series = response.data['piechart_details'];

          var data = response.data["piechart_details"];
          var sum = 0;
          data.forEach((e) => {
            sum += e;
          });
          if (sum > 0) {
            this.pieChart.series = data;
            this.show_pie = true;
          } else {
            this.pieChart.series = [1, 1, 1, 1, 1, 1];
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // callApi(toggleBtnUrl) {
    //   console.log("API called!");
    //   this.$emit("apiCalled");
    // },
    toggleCheckbox(toggleBtnUrl, event) 
      {
      // this.callApi(toggleBtnUrl);
      this.checkbox = !this.checkbox;
      console.log(toggleBtnUrl, this.checkbox, localStorage.getItem("accessToken"), event.target.checked)
      axios.post("/applications/toggle_verify/", { aid: toggleBtnUrl, verifyit:event.target.checked, tn: localStorage.getItem("accessToken"), })
        .then((response) => {
          if (response.data.status == "SUCCESS") {
            const indexToUpdate = this.arrItem.findIndex(item => item.applicantId === parseInt(response.data.aid));
            console.log("status____", response.data.status, indexToUpdate, response.data.newstatus);
            console.log(this.arrItem[indexToUpdate])
            if (indexToUpdate != -1) {   this.arrItem[indexToUpdate].statusLog = response.data.newstatus;   }
            console.log(this.arrItem[indexToUpdate])
          }
          // window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
      },
  },
};
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.slider { 
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.checkBoxCstmStl {
  opacity: 0;
  visibility: hidden;
}
input:checked + .slider {
  background-color: #f48226;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f48226;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
</style>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px; /* Adjust the border-radius as needed */
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px; /* Adjust the height of the circle as needed */
  width: 16px; /* Adjust the width of the circle as needed */
  left: 2px; /* Adjust the left offset as needed */
  bottom: 2px; /* Adjust the bottom offset as needed */
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(
    18px
  ); /* Adjust the translateX value as needed */
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}
</style>

<template>
  <Layout>
    <main class="py-4">
      <div class="row mb-3">
        <div class="col">
          <h2 class="font-size-22 text-theme-dark">Dashboard</h2>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-8">
          <div class="row">
            <div class="col-md-6">
              <div class="card cardCustomDash">
                <div class="card-body p-3">
                  <div class="row gx-3 align-items-center">
                    <div class="col-auto">
                      <div class="iconStyleCol">
                        <span>
                          <i class="mdi mdi-account-group-outline"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="contentColMem">
                        <h3 class="mb-0">{{ Total_Staff_Members }}</h3>
                        <p class="mb-0">Total Staff Members</p>
                      </div>
                    </div>
                    <div class="col-auto">
                      <router-link to="/staff_users" class="arrowLinkStyle">
                        <i class="mdi mdi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card cardCustomDash">
                <div class="card-body p-3">
                  <div class="row gx-3 align-items-center">
                    <div class="col-auto">
                      <div class="iconStyleCol">
                        <span>
                          <i class="mdi mdi-account-multiple-plus"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="contentColMem">
                        <h3 class="mb-0">{{ Total_Applicants }}</h3>
                        <p class="mb-0">Total Applicants</p>
                      </div>
                    </div>
                    <div class="col-auto">
                      <router-link to="/applicants" class="arrowLinkStyle">
                        <i class="mdi mdi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card cardCustomDash">
                <div class="card-body p-3">
                  <div class="row gx-3 align-items-center">
                    <div class="col-auto">
                      <div class="iconStyleCol">
                        <span>
                          <i class="mdi mdi-account-multiple-check-outline"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="contentColMem">
                        <h3 class="mb-0">{{ Total_Approved_Applications }}</h3>
                        <p class="mb-0">Total Approved Applications</p>
                      </div>
                    </div>
                    <div class="col-auto">
                      <router-link
                        to="/approved_application"
                        class="arrowLinkStyle"
                      >
                        <i class="mdi mdi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card cardCustomDash">
                <div class="card-body p-3">
                  <div class="row gx-3 align-items-center">
                    <div class="col-auto">
                      <div class="iconStyleCol">
                        <span>
                          <i
                            class="mdi mdi-account-multiple-remove-outline"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="contentColMem">
                        <h3 class="mb-0">{{ Total_Rejected_Applications }}</h3>
                        <p class="mb-0">Total Rejected Applications</p>
                      </div>
                    </div>
                    <div class="col-auto">
                      <router-link
                        to="/rejected_application"
                        class="arrowLinkStyle"
                      >
                        <i class="mdi mdi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card cardCustomDash">
                <div class="card-body p-3">
                  <div class="row gx-3 align-items-center">
                    <div class="col-auto">
                      <div class="iconStyleCol">
                        <span>
                          <i class="mdi mdi-account-off"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="contentColMem">
                        <h3 class="mb-0">
                          {{ Total_Incomplete_Applications }}
                        </h3>
                        <p class="mb-0">Total Incomplete Applications</p>
                      </div>
                    </div>
                    <div class="col-auto">
                      <router-link
                        to="/incomplete_application"
                        class="arrowLinkStyle"
                      >
                        <i class="mdi mdi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card cardCustomDash">
                <div class="card-body p-3">
                  <div class="row gx-3 align-items-center">
                    <div class="col-auto">
                      <div class="iconStyleCol">
                        <span>
                          <i class="mdi mdi-account-clock"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col">
                      <div class="contentColMem">
                        <h3 class="mb-0">{{ Submitted_and_Under_Review }}</h3>
                        <p class="mb-0">Submitted and Under Review</p>
                      </div>
                    </div>
                    <div class="col-auto">
                      <router-link to="/submit" class="arrowLinkStyle">
                        <i class="mdi mdi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body" v-if="show_pie">
              <h3 class="card-title">Daily Report Membership</h3>
              <!-- <ReportStatus /> -->
              <apexchart
                class="apex-charts"
                height="289"
                type="pie"
                dir="ltr"
                :series="pieChart.series"
                :options="pieChart.chartOptions"
              >
              </apexchart>
            </div>
            <div class="card-body" v-else>
              <p class="card-title">
                Charts for Accepted, Rejected and incomplete data will be shown
                here.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col">
              <h2 class="font-size-18 mb-3">All Applications</h2>
            </div>
            <div class="col-lg-6 mt-3 mt-md-0">
              <div class="row">
                <div class="col-md-5">
                  <select
                    class="form-select form-control selectCustomSize"
                    v-model="selectFilterSort"
                    name="favouriteSport"
                    @change="filterStatus"
                  >
                    <option selected :value="null">All Status</option>
                    <option value="submitted and under review">
                      Submitted and Under Review
                    </option>
                    <option value="incomplete">Incomplete</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="rejected">Verified</option>
                  </select>
                </div>
                <div class="col mt-3 mt-md-0">
                  <div class="groupSearchTop">
                    <input
                      type="text"
                      v-model="searchValueTable"
                      placeholder="Search..."
                      class="customInputForm form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EasyDataTable
            :headers="headers"
            :items="arrItem"
            :search-value="searchValueTable"
            border-cell
            theme-color="#df6a0d"
            :rows-per-page="10"
            buttons-pagination
            table-class-name="table-custom-style noWrapHeading"
          >
            <template #item-statusLog="{ statusLog }">
              <p
                class="badge badge-pill font-size-11"
                :class="{
                  'badge-soft-success': `${statusLog}` === 'approved',
                  'badge-soft-danger': `${statusLog}` === 'rejected',
                  'badge-soft-primary':
                    `${statusLog}` === 'inprogress' ||
                    `${statusLog}` === 'InReview',
                  'badge-soft-warning': `${statusLog}` === 'incomplete',
                  'badge-soft-dark': `${statusLog}` === 'submitted',
                }"
              >
                {{ statusLog }}
              </p>
            </template>
            <template #item-logdate="{ dateLog, timeLog }">
              <p class="mb-0 text-nowrap fw-medium">{{ dateLog }}</p>
              <p class="mb-0 text-nowrap">{{ timeLog }}</p>
            </template>
            <template #item-actionBtn="{ actionBtnUrl }">
              <router-link
                :to="`${actionBtnUrl}`"
                class="btn btn-sm btn-theme-dark"
              >
                <i class="mdi mdi-eye"></i>
                View
              </router-link>
              <p class="mb-0 text-nowrap fw-medium">{{ dateLog }}</p>
              <p class="mb-0 text-nowrap">{{ timeLog }}</p>
            </template>
            <template #item-toggleBtn="{ toggleBtnUrl, statusLog }">
              <label class="switch" v-if="(statusLog=='submitted' || statusLog=='verify')">
                <input
                  type="checkbox"
                  class="checkBoxCstmStl"
                  :checked="(statusLog=='verify')"
                  @click="toggleCheckbox(toggleBtnUrl, $event)"
                />
                <div class="slider round"></div>
              </label>
            </template>
            <!-- <template #item-toggleBtn="{ toggleBtnUrl }">
              <router-link :to="toggleBtnUrl" @click="toggleSwitch">
                <label class="switch">
                  <input type="checkbox" v-model="toggleState" />
                  <span class="slider round"></span>
                </label>
              </router-link>
            </template> -->
          </EasyDataTable>
        </div>
      </div>
    </main>
  </Layout>
</template>